import { FC } from 'react';

export const ExclamationCircleIcon: FC = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 95 91"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M48 8.6875C34.8164 8.6875 22.8633 15.7188 16.1836 26.9688C9.67969 38.3945 9.67969 52.2812 16.1836 63.5312C22.8633 74.957 34.8164 81.8125 48 81.8125C61.0078 81.8125 72.9609 74.957 79.6406 63.5312C86.1445 52.2812 86.1445 38.3945 79.6406 26.9688C72.9609 15.7188 61.0078 8.6875 48 8.6875ZM48 90.25C31.8281 90.25 17.0625 81.8125 8.97656 67.75C0.890625 53.8633 0.890625 36.8125 8.97656 22.75C17.0625 8.86328 31.8281 0.25 48 0.25C63.9961 0.25 78.7617 8.86328 86.8477 22.75C94.9336 36.8125 94.9336 53.8633 86.8477 67.75C78.7617 81.8125 63.9961 90.25 48 90.25ZM48 22.75C50.2852 22.75 52.2188 24.6836 52.2188 26.9688V46.6562C52.2188 49.1172 50.2852 50.875 48 50.875C45.5391 50.875 43.7812 49.1172 43.7812 46.6562V26.9688C43.7812 24.6836 45.5391 22.75 48 22.75ZM53.625 62.125C53.625 65.2891 50.9883 67.75 48 67.75C44.8359 67.75 42.375 65.2891 42.375 62.125C42.375 59.1367 44.8359 56.5 48 56.5C50.9883 56.5 53.625 59.1367 53.625 62.125Z"
				fill="#28BE3F"
			/>
		</svg>
	);
};
