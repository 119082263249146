import { FC, useEffect, useState } from 'react';
import { ChatGPTResponse, Conversations } from '../../props';
import { Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import type { MenuProps } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import styles from './styles.module.css';

interface SidebarMenuProps {
	onClick: (e: MenuInfo) => void;
	conversationId: string;
}

export const SidebarMenu: FC<ChatGPTResponse & SidebarMenuProps> = ({
	messages,
	onClick,
	conversationId,
}) => {
	const [chatsMenuItems, setChatsMenuItems] = useState<MenuProps['items']>();

	useEffect(() => {
		const existingChatsJSON = localStorage.getItem('chats');
		if (existingChatsJSON) {
			const existingChats: Conversations[] = JSON.parse(
				existingChatsJSON
			) as Conversations[];
			const items = existingChats.reverse().map((ec) => {
				const content: string = ec.conversation[0].content;
				return {
					label: content,
					key: ec.id,
					icon: <MessageOutlined />,
					onClick,
				};
			});
			setChatsMenuItems(items);
		}
	}, [messages]);

	return (
		<Menu
			className={styles.chatsMenu}
			mode="inline"
			items={chatsMenuItems}
			selectedKeys={[conversationId]}
		/>
	);
};
