import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { ChatGPTPromt, ChatGPTResponse, FormProps } from './props';
import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { MenuInfo } from 'rc-menu/lib/interface';
import {
	getChatsLocalStorage,
	getConsent,
	setChatLocalStorage,
} from './helpers/localStorage';
import { APIConfig } from './configs/api';
import { ThemeMode, TendiumLogo } from './TendiumLogo';
import { Chat, Consent, IntroPromt, SidebarMenu } from './components';
import { CTABanner } from './components/CTABanner';

async function sendQuestion(
	payload: ChatGPTPromt[],
	email: string
): Promise<ChatGPTResponse> {
	const response = await fetch(
		`${APIConfig.baseUrl}/message`,

		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				payload: { messages: payload },
				metadata: { email },
			}),
		}
	);

	if (!response.ok) {
		throw new Error('Failed to fetch data from the API');
	}

	const data = (await response.json()) as ChatGPTResponse;

	return data;
}

const App: FC = () => {
	const [conversation, setConversation] = useState<ChatGPTPromt[]>([]);
	const [conversationId, setConversationId] = useState<string>(uuidv4());
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);

	const [collapsed, setCollapsed] = useState<boolean>(window.innerWidth < 576);
	const siderRef = useRef<HTMLDivElement>(null);

	const [form] = Form.useForm();

	const [isEditingTextArea, setIsEditingTextArea] = useState<boolean>(false);

	const [policyAccepted, setPolicyAccepted] = useState<boolean>(
		getConsent() !== null
	);

	const onClickMenuItem = (e: MenuInfo) => {
		let existingChats = getChatsLocalStorage();
		existingChats = existingChats.filter((c) => c.id === e.key);
		setConversation(existingChats[0].conversation);
		setConversationId(e.key);
	};

	const addPromtToChat = (values: FormProps): ChatGPTPromt[] => {
		const existingChats = getChatsLocalStorage();
		const newConversation = [...conversation];
		newConversation.push({ role: 'USER', content: values.question ?? '' });
		setConversation(newConversation);
		form.resetFields();
		setChatLocalStorage({
			conversationId,
			conversation: newConversation,
			existingChats,
		});
		return newConversation;
	};

	const addAnswereToChat = (
		answere: string,
		currentConversation: ChatGPTPromt[]
	) => {
		const existingChats = getChatsLocalStorage();
		const answereConversation = [...currentConversation];
		answereConversation.push({ role: 'SYSTEM', content: answere ?? '' });
		setConversation(answereConversation);
		setChatLocalStorage({
			conversationId,
			conversation: answereConversation,
			existingChats,
		});
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
		if (
			event.key === 'Shift' ||
			event.key === 'Alt' ||
			event.key === 'Control'
		) {
			setIsEditingTextArea(true);
		}

		if (event.key === 'Enter' && !isEditingTextArea) {
			event.preventDefault();
			form.submit();
		}
	};

	const handleKeyUp = (event: KeyboardEvent<HTMLTextAreaElement>) => {
		if (
			event.key === 'Shift' ||
			event.key === 'Alt' ||
			event.key === 'Control'
		) {
			setIsEditingTextArea(false);
		}
	};

	async function send(values: FormProps) {
		if (values.question.length === 0) return;
		setLoading(true);
		setError(false);
		const newConversation = addPromtToChat(values);
		const email = getConsent();

		try {
			if (email) {
				const resp = await sendQuestion(newConversation, email);
				const latestResponse = resp.messages[resp.messages.length - 1];
				addAnswereToChat(latestResponse.content, newConversation);
			}
		} catch {
			setError(true);
		}
		form.resetFields();
		setLoading(false);
	}

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (
				siderRef.current &&
				!siderRef.current.contains(event.target as Node)
			) {
				if (!collapsed && window.innerWidth < 576) {
					setCollapsed(true);
				}
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [siderRef, collapsed]);

	const createNewChat = () => {
		setConversationId(uuidv4());
		setConversation([]);
	};

	useEffect(() => {
		const inputRef = form.getFieldInstance(
			'question'
		) as HTMLInputElement | null;
		if (inputRef) {
			inputRef.focus();
		}
	}, [conversation]);

	return (
		<Layout>
			<CTABanner collapsed={collapsed} />
			<Layout>
				<Sider
					ref={siderRef}
					breakpoint="sm"
					collapsedWidth="0"
					onCollapse={(isCollapsed) => {
						setCollapsed(isCollapsed);
					}}
					collapsed={collapsed}
					style={{
						overflowY: collapsed ? 'unset' : 'auto',
						height: collapsed ? 'unset' : 'calc(100vh)',
						backgroundColor: 'var(--brand-la)',
					}}
					className={collapsed ? '' : 'open'}
				>
					<div style={{ height: 32, margin: 16 }}>
						<TendiumLogo mode={ThemeMode.dark} />
					</div>
					<Button
						icon={<PlusOutlined />}
						size={'large'}
						onClick={createNewChat}
						className="primary-button"
					>
						Ny konversation
					</Button>
					<SidebarMenu
						messages={conversation}
						onClick={onClickMenuItem}
						conversationId={conversationId}
					/>
				</Sider>
				<Content
					style={{
						height: 'calc(100vh - 54px)',
						width: '100%',
						paddingBottom: '60px',
						background: 'white',
						overflow: 'auto',
					}}
					id="chatContent"
				>
					<div
						style={{
							padding: 24,
							minHeight: 'calc(100vh - 52px - 54px)',
							background: 'white',
							display: 'flex',
						}}
					>
						{policyAccepted ? (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<IntroPromt />
								<Chat
									conversation={conversation}
									loading={loading}
									error={error}
								/>
							</div>
						) : (
							<Consent setPolicyAccepted={setPolicyAccepted} />
						)}
					</div>
				</Content>
				<Content
					style={{
						position: 'fixed',
						right: 0,
						bottom: 0,
						width: collapsed ? '100%' : 'calc(100% - 200px)',
						backgroundColor: 'white',
						padding: '10px',
					}}
				>
					<Form onFinish={send} form={form} disabled={!policyAccepted}>
						<Form.Item name="question" style={{ flex: 1, margin: 0 }}>
							<Input.TextArea
								placeholder="Ställ en fråga"
								autoComplete="off"
								autoSize={{ minRows: 1, maxRows: 6 }}
								onKeyDown={(e) => handleKeyDown(e)}
								onKeyUp={(e) => handleKeyUp(e)}
								disabled={loading || !policyAccepted}
							/>
						</Form.Item>
						<Button
							type="primary"
							style={{
								backgroundColor: 'transparent',
								border: 'none',
								boxShadow: 'none',
								position: 'absolute',
								bottom: 10,
								right: 8,
							}}
							htmlType="submit"
							loading={loading}
							icon={<SendOutlined style={{ color: 'var(--brand-re)' }} />}
						/>
					</Form>
				</Content>
			</Layout>
		</Layout>
	);
};

export default App;
