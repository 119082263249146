import { FC, useState } from 'react';
import { getHideCTABanner, setHideCTABanner } from '../../helpers/localStorage';
import { Header } from 'antd/es/layout/layout';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { CTABannerProps } from './CTABannerProps';
import styles from './styles.module.css';

export const CTABanner: FC<CTABannerProps> = ({ collapsed }) => {
	const [showCTABanner, setShowCTABanner] = useState<boolean>(
		getHideCTABanner() === null
	);
	const onCloseCTABanner = () => {
		setHideCTABanner('true');
		setShowCTABanner(false);
	};
	return (
		<>
			{showCTABanner && (
				<Header className={styles.CTABannerHeader}>
					<span className={styles.CTABannerContent}>
						Vill du vara först med att testa nästa version, när Louis har övat
						sig på rättsfall och praxis?
						<a
							href="https://tendium.ai/se/vantalista-louis/"
							target="_blank"
							rel="noreferrer"
						>
							<Button
								style={{
									background: 'transparent',
									color: 'white',
									margin: collapsed ? '8px 0 0 0' : '0 16px',
								}}
								className="no-color"
							>
								Gå med i väntelista
							</Button>
						</a>
					</span>
					<CloseOutlined
						className={styles.CTABannerIcon}
						onClick={onCloseCTABanner}
					/>
				</Header>
			)}
		</>
	);
};
