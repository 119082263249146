import { FC } from 'react';
import styles from './styles.module.css';

export const TypingIndicator: FC = () => {
	return (
		<div className={styles.typeIndicator}>
			<div className={styles.dotFlashing} />
		</div>
	);
};
