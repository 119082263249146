import { FC } from 'react';

export const MessageBotIcon: FC = () => {
	return (
		<svg
			width="54"
			height="54"
			viewBox="0 0 188 152"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M79.9375 108.562V122.625V127.312L101.031 111.492L104.84 108.562H109.527H140.875C143.219 108.562 145.562 106.512 145.562 103.875V57V19.5C145.562 17.1562 143.219 14.8125 140.875 14.8125H47.125C44.4883 14.8125 42.4375 17.1562 42.4375 19.5V57V103.875C42.4375 106.512 44.4883 108.562 47.125 108.562H65.875H79.9375ZM28.375 61.6875H17.5352C16.0703 64.6172 12.8477 66.375 9.625 66.375C4.35156 66.375 0.25 62.2734 0.25 57C0.25 52.0195 4.35156 47.625 9.625 47.625C12.8477 47.625 16.0703 49.6758 17.5352 52.3125H28.375V19.5C28.375 9.24609 36.5781 0.75 47.125 0.75H140.875C151.129 0.75 159.625 9.24609 159.625 19.5V52.3125H170.172C171.637 49.6758 174.859 47.625 178.375 47.625C183.355 47.625 187.75 52.0195 187.75 57C187.75 62.2734 183.355 66.375 178.375 66.375C174.859 66.375 171.637 64.6172 170.172 61.6875H159.625V103.875C159.625 114.422 151.129 122.625 140.875 122.625H109.527L79.9375 144.891L79.6445 145.184L78.1797 146.062L73.1992 149.871C71.7344 151.043 69.9766 151.043 68.2188 150.457C66.7539 149.578 65.875 148.113 65.875 146.062V139.91V138.152V137.859V136.688V122.625H51.8125H47.125C36.5781 122.625 28.375 114.422 28.375 103.875V61.6875ZM51.8125 38.25C51.8125 33.2695 55.9141 28.875 61.1875 28.875H126.812C131.793 28.875 136.188 33.2695 136.188 38.25V75.75C136.188 81.0234 131.793 85.125 126.812 85.125H61.1875C55.9141 85.125 51.8125 81.0234 51.8125 75.75V38.25ZM61.1875 57C61.1875 62.2734 65.2891 66.375 70.5625 66.375C75.543 66.375 79.9375 62.2734 79.9375 57C79.9375 52.0195 75.543 47.625 70.5625 47.625C65.2891 47.625 61.1875 52.0195 61.1875 57ZM117.438 47.625C112.164 47.625 108.062 52.0195 108.062 57C108.062 62.2734 112.164 66.375 117.438 66.375C122.418 66.375 126.812 62.2734 126.812 57C126.812 52.0195 122.418 47.625 117.438 47.625Z"
				fill="#28BE3F"
			/>
		</svg>
	);
};
