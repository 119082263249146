import { FC } from 'react';
import { Card, List } from 'antd';
import { ChatItemProps } from './ChatItemProps';
import styles from './styles.module.css';

export const ChatItem: FC<ChatItemProps> = ({ children, type }) => {
	return (
		<List.Item
			className={type === 'USER' ? styles.chatUserItem : styles.chatSystemItem}
		>
			<Card
				className={
					type === 'USER' ? styles.chatUserItemCard : styles.chatSytemItemCard
				}
			>
				{children}
			</Card>
		</List.Item>
	);
};
