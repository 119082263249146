import { Button, Checkbox, Form, Input } from 'antd';
import { Dispatch, FC, SetStateAction } from 'react';
import pdf from '../../disclaimer.pdf';
import { setConsent } from '../../helpers/localStorage';
import styles from './styles.module.css';

export interface ConstentFormProps {
	email: string;
}

export const Consent: FC<{
	setPolicyAccepted: Dispatch<SetStateAction<boolean>>;
}> = ({ setPolicyAccepted }) => {
	const [form] = Form.useForm();

	const onFinish = (values: ConstentFormProps) => {
		setConsent(values.email);
		setPolicyAccepted(true);
	};

	return (
		<div className={styles.consentContainer}>
			<Form
				onFinish={onFinish}
				form={form}
				layout="vertical"
				className={styles.consentForm}
			>
				<h1>
					Välkommen till Louis - vår AI som svarar på dina frågor om
					upphandlingsjuridik
				</h1>
				<p>
					Det här är första versionen av Louis, som tränar för att bli expert på
					upphandlingslagstiftning. Skriv in din mejladress nedan för att börja
					chatta med Louis.
				</p>
				<Form.Item
					name="email"
					label="Mejladress"
					rules={[{ required: true, message: 'Mejladress krävs' }]}
				>
					<Input
						type="email"
						placeholder="Fyll i din mejladress"
						autoComplete="off"
					/>
				</Form.Item>
				<Form.Item
					name="consent"
					valuePropName="checked"
					rules={[
						{
							validator: (_, value) =>
								value
									? Promise.resolve()
									: Promise.reject(new Error('Godkännande krävs')),
						},
					]}
				>
					<Checkbox>
						Jag intygar härmed att jag har tagit del av och godkänner Tendiums
						<a href={pdf as string} target="_blank" rel="noreferrer">
							{' '}
							användarpolicy
						</a>
						. Läs mer om hur vi behandlar personuppgifter i vår
						<a
							href="https://tendium.ai/se/privacy-policy/"
							target="_blank"
							rel="noreferrer"
						>
							{' '}
							integritetspolicy
						</a>
						.
					</Checkbox>
				</Form.Item>
				<Form.Item>
					<Button className="primary-button" htmlType="submit">
						Börja chatta nu
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};
