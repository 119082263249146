import { FC } from 'react';

export const QuestionCircleIcon: FC = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 90 95"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M81.5625 47.25C81.5625 34.2422 74.5312 22.2891 63.2812 15.6094C51.8555 9.10547 37.9688 9.10547 26.7188 15.6094C15.293 22.2891 8.4375 34.2422 8.4375 47.25C8.4375 60.4336 15.293 72.3867 26.7188 79.0664C37.9688 85.5703 51.8555 85.5703 63.2812 79.0664C74.5312 72.3867 81.5625 60.4336 81.5625 47.25ZM0 47.25C0 31.2539 8.4375 16.4883 22.5 8.40234C36.3867 0.316406 53.4375 0.316406 67.5 8.40234C81.3867 16.4883 90 31.2539 90 47.25C90 63.4219 81.3867 78.1875 67.5 86.2734C53.4375 94.3594 36.3867 94.3594 22.5 86.2734C8.4375 78.1875 0 63.4219 0 47.25ZM29.707 31.4297C31.1133 27.3867 34.8047 24.75 39.0234 24.75H49.2188C55.3711 24.75 60.4688 29.8477 60.4688 36C60.4688 39.8672 58.1836 43.5586 54.8438 45.4922L49.2188 48.832C49.043 51.1172 47.2852 52.875 45 52.875C42.5391 52.875 40.7812 51.1172 40.7812 48.6562V46.3711C40.7812 44.7891 41.4844 43.3828 42.8906 42.6797L50.625 38.2852C51.5039 37.7578 52.0312 36.8789 52.0312 36C52.0312 34.418 50.8008 33.3633 49.2188 33.3633H39.0234C38.4961 33.3633 37.9688 33.7148 37.793 34.2422L37.6172 34.418C36.9141 36.7031 34.4531 37.7578 32.3438 37.0547C30.0586 36.1758 29.0039 33.7148 29.707 31.6055V31.4297ZM39.375 64.125C39.375 61.1367 41.8359 58.5 45 58.5C47.9883 58.5 50.625 61.1367 50.625 64.125C50.625 67.2891 47.9883 69.75 45 69.75C41.8359 69.75 39.375 67.2891 39.375 64.125Z"
				fill="#28BE3F"
			/>
		</svg>
	);
};
