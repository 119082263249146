import { FC } from 'react';
import styles from './styles.module.css';
import {
	ExclamationCircleIcon,
	MessageBotIcon,
	QuestionCircleIcon,
} from '../Icons';
import { IntroPromtBox } from '../IntroPromtBox';

export const IntroPromt: FC = () => {
	return (
		<div className={styles.introPromptContainer}>
			<MessageBotIcon />
			<h1>Välkommen till Louis!</h1>
			<p className={styles.introPromptParagraph}>
				Nu kan vi börja chatta. Glöm inte att Louis är ny på jobbet, och håller
				på att tränas i att ge bra juridiska svar. Det är också viktigt att
				tänka på att Louis inte gör juridiska bedömningar, utan svaren ska ses
				som fingervisningar och tips som du sedan kan arbeta vidare med och
				utreda utifrån ditt specifika fall.
			</p>
			<div className={styles.introPromtBoxContainer}>
				<div>
					<QuestionCircleIcon />
					<h4 className={styles.introPromtBoxHeading}>Hur fungerar det?</h4>
					<IntroPromtBox>
						<p>
							Ställ en tydlig fråga om lagar och regler om offentlig
							upphandling.
						</p>
						<p>
							Louis svarar utifrån lagstiftning, förordningar, EU-direktiv m.m.
						</p>
					</IntroPromtBox>
				</div>
				<div>
					<ExclamationCircleIcon />
					<h4 className={styles.introPromtBoxHeading}>Vad ska jag tänka på?</h4>
					<IntroPromtBox>
						<p>
							Testa att formulera om din fråga eller starta en ny konversation
							vid behov.
						</p>
						<p>
							Var medveten om att felaktiga svar kan genereras. Louis är inte en
							jurist.
						</p>
					</IntroPromtBox>
				</div>
			</div>
		</div>
	);
};
