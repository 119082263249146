import { ExclamationCircleOutlined } from '@ant-design/icons';
import { List } from 'antd';
import { FC, useEffect } from 'react';
import styles from './styles.module.css';
import { ChatProps } from './ChatProps';
import { ChatItem } from '../ChatItem';
import { TypingIndicator } from '../TypingIndicator';

export const Chat: FC<ChatProps> = ({ conversation, loading, error }) => {
	useEffect(() => {
		const chatContent: HTMLElement | null =
			document.getElementById('chatContent');
		if (chatContent) {
			chatContent.scroll({ top: chatContent.scrollHeight, behavior: 'smooth' });
		}
	}, [conversation, error, document.activeElement]);

	return (
		<List style={{ width: '100%' }}>
			{conversation.map((c, i) => {
				return (
					<ChatItem key={`chat-item-${i}`} type={c.role}>
						{c.content}
					</ChatItem>
				);
			})}
			{loading && (
				<List.Item key="list-item-loading" className={styles.listItemLoading}>
					<TypingIndicator />
				</List.Item>
			)}
			{error && (
				<List.Item key="list-item-error" className={styles.listItemError}>
					<ExclamationCircleOutlined /> Oops något gick fel, testa ställa en ny
					fråga
				</List.Item>
			)}
		</List>
	);
};
