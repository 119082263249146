import { ChatGPTPromt, Conversations } from '../props';

export const getChatsLocalStorage = (): Conversations[] => {
	const existingChatsJSON = localStorage.getItem('chats');
	let existingChats: Conversations[] = [];
	if (existingChatsJSON) {
		existingChats = JSON.parse(existingChatsJSON) as Conversations[];
	}

	return existingChats;
};

interface SetChatLocalStorageProps {
	conversationId: string;
	conversation: ChatGPTPromt[];
	existingChats: Conversations[];
}

export const setChatLocalStorage = ({
	conversationId,
	conversation,
	existingChats,
}: SetChatLocalStorageProps): void => {
	const con: Conversations = {
		id: conversationId,
		conversation,
	};
	const filteredConversation = existingChats.filter(
		(c) => c.id !== conversationId
	);
	localStorage.setItem('chats', JSON.stringify([...filteredConversation, con]));
};

export const setConsent = (email: string) => {
	localStorage.setItem('email', email);
};

export const getConsent = (): string | null => {
	return localStorage.getItem('email');
};

export const setHideCTABanner = (hide: string) => {
	localStorage.setItem('hide-cta-banner', hide);
};

export const getHideCTABanner = (): string | null => {
	return localStorage.getItem('hide-cta-banner');
};
